<template>
  <component
    v-cloak
    :is="layout.comp"
  />
  <vue-cookie-comply
    :preferences="preferences"
    @on-accept-all-cookies="onAccept"
    @on-save-cookie-preferences="onSavePreferences"
    @on-reject-all-cookies="onReject"
    preferences-label="Çerez Ayarları"
    accept-all-label="Tümünü Kabul et"
    header-title="Sana Özel Bir Deneyim Sunuyoruz"
    header-description="İnternet sitemizde deneyimlerinizi kişiselleştirmek amacıyla çerezler kullanılmakta olup, izin vermeniz halinde zorunlu çerezler haricindeki çerezlerle toplanan veriler işlenmekte ve tüm çerezler yurt dışında yerleşik altyapı tedarikçilerimize aktarılmaktadır.Reddet seçeneğine tıklamanız halinde tercih ve ilgi alanlarına yönelik maalesef sana özel bir deneyim sunamayacağız."
    show-accept-all-in-modal
  >
    <template #modal-header>
      <header>Çerez Ayarları</header>
    </template>
  </vue-cookie-comply>
</template>
<script setup>
import DefaultLayout from '@/layout/DefaultLayout'
import UserLayout from '@/layout/UserLayout'
import PremiumLayout from '@/layout/PremiumLayout'
import SidebarLayout from '@/layout/SidebarLayout'
import { computed, onMounted, provide, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import questionnaire from '@/graphql/questionnaireForNav.query.graphql'
import { useStore } from 'vuex'
import { setLocale } from '@vee-validate/i18n'

const query = useQuery(questionnaire)
const route = useRoute()
const router = useRouter()
const store = useStore()
const questionnaireList = computed(() => query.result?.value?.questionnairesForNav ?? [])
provide('profiles', questionnaireList)
const components = [
  { name: 'DefaultLayout', comp: DefaultLayout },
  { name: 'UserLayout', comp: UserLayout },
  { name: 'PremiumLayout', comp: PremiumLayout },
  { name: 'SidebarLayout', comp: SidebarLayout }
]

const layout = computed(() => components.filter(item => item.name === route.meta.layout)[0] || components[0])
const didAutoLogout = computed(() => store.getters['auth/didAutoLogout'])
watch(didAutoLogout, (curVal, oldVal) => {
  if (curVal && curVal !== oldVal) {
    router.replace({ name: 'home' })
  }
})

const onAccept = () => {
  console.log('User has accepted all cookies')
}

const onReject = () => {
  console.log('User has rejected all cookies')
}

const onSavePreferences = (preferences) => {
  console.log(preferences) // ['performance', 'ga', 'newRelic']
}

const preferences = [
  {
    title: 'Zorunlu Çerezler',
    description: 'Bu tanımlama bilgileri, web sitesinin çalışması için gereklidir ve sistemlerimizde kapatılamaz. Bunlar genellikle yalnızca sizin işlemlerinizi gerçekleştirmek için ayarlanmıştır. Bu işlemler, gizlilik tercihlerinizi belirlemek, oturum açmak veya form doldurmak gibi hizmet taleplerinizi içerir. Tarayıcınızı, bu tanımlama bilgilerini engelleyecek veya bunlar hakkında sizi uyaracak şekilde ayarlayabilirsiniz ancak bu durumda sitenin bazı bölümleri çalışmayabilir.',
    items: [{ label: 'Aktif', value: 'performance', isRequired: true }]
  },
  {
    title: 'Performans Çerezleri',
    description: 'Bu tanımlama bilgileri, sitemizin performansını ölçebilmemiz ve iyileştirebilmemiz için sitenin ziyaret edilme sayısını ve trafik kaynaklarını sayabilmemizi sağlar. Hangi sayfaların en fazla ve en az ziyaret edildiğini ve ziyaretçilerin sitede nasıl gezindiklerini öğrenmemize yardımcı olurlar. Bu tanımlama bilgilerinin topladığı tüm bilgiler derlenir ve bu nedenle anonimdir. Bu tanımlama bilgilerine izin vermezseniz sitemizi ne zaman ziyaret ettiğinizi bilemeyiz.',
    items: [{ label: 'GoogleAnalytics', description: 'Google Analytics Description', value: 'ga', isEnable: true }]
  }
]

onMounted(() => {
  store.dispatch('auth/autoLogin')
  store.dispatch('product/products')
  store.dispatch('product/productTable', { id: 1 })
  store.dispatch('product/productTable', { id: 2 })
  store.dispatch('product/productTable', { id: 3 })
  store.dispatch('product/categories')
  store.commit('cart/updateCartFromLocal')
  setLocale('tr')
  if (navigator.userAgent.indexOf('iPhone') > -1) {
    document
      .querySelector('[name=viewport]')
      .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
  }
  const touchSupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
  if (touchSupport) {
    document.documentElement.className += 'touchable'
  }
})
</script>
<style lang="scss">
[v-cloak] { display: none; }
.cookie-comply {
  position: fixed !important;
  z-index: 100000;

  &__modal {
    z-index: 5000;

    &-inner {
      position: fixed !important;
      width: 60rem !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;

      & main {
        width: 100%;
      }
    }

    &-header {
      display: flex;
      font-size: 2rem;
      justify-content: center;
      align-items: center;
    }

    &-content {
      max-height: 50vh !important;

      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
      }

      & > div {
        width: 95%;
        margin: auto;
      }
    }

    &-switches {
      margin: 1rem 0;

      & > h3 {
        margin-bottom: 0;
      }
    }
  }
}
</style>
