<template>
  <div
    :class="typeClass"
    class="notification__item fade show"
    role="alert"
  >
    <span
      class="material-icons notification__icon"
      v-if="notification.icon"
    >{{ notification.icon }}</span>
    {{ notification.message }}
    <button
      type="button"
      class="notification__close"
      data-dismiss="alert"
      aria-label="Close"
      @click="close"
    >
      <span
        class="material-icons"
        aria-hidden="true"
      >close</span>
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, onBeforeUnmount } from 'vue'

const props = defineProps({ notification: { type: Object, required: true } })
const typeClass = computed(() => `notification--${props.notification.type}`)
const store = useStore()
const close = () => store.commit('notification/removeNotification', props.notification)
const timeout = setTimeout(() => { store.commit('notification/removeNotification', props.notification) }, 5000)
onBeforeUnmount(() => clearTimeout(timeout))
</script>
