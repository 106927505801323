<template>
  <div
    class="accordion__wrapper"
    :class="{ open: modelValue, 'accordion__wrapper--shadow': shadow, 'accordion__wrapper--rounded': rounded }"
  >
    <button
      @click="toggleAccordion"
      :class="titleClass"
      :aria-expanded="modelValue"
      :aria-controls="`collapse-${id}`"
      :disabled="disabled"
      type="button"
    >
      <slot name="title" />
      <template v-if="!disabled">
        <template v-if="icon === 'chevron'">
          <span
            v-if="modelValue"
            class="material-icons"
          >keyboard_arrow_down</span>
          <span
            v-else
            class="material-icons"
          >keyboard_arrow_right</span>
        </template>
        <template v-else-if="icon === 'plus'">
          <span
            v-if="modelValue"
            class="material-icons"
          >remove</span>
          <span
            v-else
            class="material-icons"
          >add</span>
        </template>
      </template>
    </button>
    <slot name="afterTitle" />
    <div
      :class="contentClass"
      v-show="modelValue"
      :id="`collapse-${id}`"
    >
      <slot name="content" />
    </div>
  </div>
</template>
<script setup>
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: { type: String, required: true },
  modelValue: { type: Boolean, default: false },
  titleClass: { type: String, default: 'sidebar__nav-button' },
  contentClass: { type: String, default: 'sidebar__nav-button-wrapper' },
  icon: { type: String, default: 'chevron' },
  disabled: { type: Boolean, default: false },
  shadow: { type: Boolean, default: false },
  rounded: { type: Boolean, default: false }
})

// eslint-disable-next-line no-return-assign
const toggleAccordion = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>
<style lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.accordion__wrapper {
  width: 100%;
  position: relative;

  &--shadow {
    box-shadow: 0 0 8px 0 rgba(135, 133, 133, 0.25);
  }

  &--rounded {
    border-radius: $border-radius-s;
  }

  &.open {
    button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@include screen(s) {
  .accordion__wrapper {
    position: unset;
  }

  .sidebar__nav-button-wrapper {
    padding: 0 !important;
    position: absolute;
    bottom: 8rem;
    background-color: $color-white;
    width: 100%;
    height: 18rem;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important;

    & > .sidebar__nav-button-link {
      width: 100%;
      padding: 1.4rem 2.2rem;
      font-size: 1.8rem;

      &:not(:last-child) {
        border-bottom: 1px solid $color-border-light;
      }
    }
  }
}
</style>
