<template>
  <div class="notification">
    <notification-message
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script setup>
import NotificationMessage from './NotificationMessage'
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const notifications = computed(() => store.getters['notification/notifications'])
</script>
<style lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.notification {
  position: fixed;
  top: 4rem;
  right: 0;
  margin-right: 2rem;
  width: 38rem;
  z-index: 6000;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;
    color: inherit;
    background-color: transparent;
    border: none;

    & > span {
      font-size: $notification-font-size;
      font-weight: 600;
    }

    &:hover {
      opacity: .7;
    }
  }

  &__icon {
    padding: .3rem;
    margin-right: 1.5rem;
    border-radius: 50%;
    background-color: $color-white;
  }

  &__item {
    font-size: $notification-font-size;
    font-weight: 600;
    position: relative;
    padding: 1.5rem 5rem 1.5rem 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &--success {
    color: $color-success;
    background-color: lighten($color-success, 35%); // #d4edda;
    border-color: lighten($color-success, 30%); // #c3e6cb;
    border-left: 1rem solid;
  }

  &--warning {
    color: $color-warning;
    background-color: lighten($color-warning, 35%);
    border-color: lighten($color-warning, 30%);
    border-left: 1rem solid;
  }

  &--info {
    color: $color-info;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-left: 1rem solid;
  }

  &--error {
    color: $color-error;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-left: 1rem solid;
  }

  @include screen(s) {
    top: $header-height;
    margin: 0;
    width: 100%;

    &__item {
      border-radius: 0;
    }
  }
}
</style>
