<template>
  <img
    :src="src"
    :srcset="srcSet"
    :alt="title"
    v-bind="attributes"
  >
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  image: { type: String, required: true },
  title: { type: String, required: true },
  lazy: { type: Boolean, default: false },
  imageSet: { type: Object, default: () => ({ }) }
})
const publicPath = process.env.BASE_URL
const attributes = computed(() => props.lazy ? { loading: 'lazy' } : {})
const src = computed(() => {
  const conditions = ['http', 'www']
  if (conditions.some(el => props.image.includes(el))) {
    return props.image
  } else {
    return `${publicPath}assets/images/${props.image}`
  }
})

const srcSet = computed(() => {
  if (!Object.keys(props.imageSet).length) { return }
  const conditions = ['http', 'www']
  const src = []
  props.imageSet.widths.forEach((width, i) => {
    if (props.imageSet.widths.length === i + 1) {
      src.push(`${publicPath}assets/images/${props.imageSet.image} ${width}w`)
    } else {
      if (conditions.some(el => props.imageSet.image.includes(el))) {
        const compiled = `${props.imageSet.image.split('.')[0]}-w${width}.${props.imageSet.image.split('.')[1]} ${width}w`
        src.push(compiled)
      } else {
        const compiled = `${props.imageSet.image.split('.')[0]}-w${width}.${props.imageSet.image.split('.')[1]} ${width}w`
        src.push(`${publicPath}assets/images/${compiled}`)
      }
    }
  })

  return src.join(', ')
})
</script>
