<template>
  <div
    class="dropdown__icon-wrapper"
    @click="show = !show"
    v-outside="onClickOutside"
  >
    <slot />
    <span
      v-if="link.title"
      :class="classList"
    >
      {{ link.title }} <span class="material-icons">expand_more</span>
    </span>
  </div>
  <transition name="slide-in-up">
    <div
      class="dropdown-menu"
      v-if="show"
    >
      <ul>
        <li
          v-for="(child, i) in link.children"
          :key="i"
        >
          <svg-sprite
            v-if="child.icon"
            :icon="child.icon"
            class="dropdown-menu__icon"
          />
          <router-link
            v-if="child.url"
            class="dropdown-menu__sub-link"
            :to="'/' + child.url"
            @click="show = false"
          >
            {{ child.title }}
          </router-link>
          <span
            v-if="child.action"
            class="dropdown-menu__sub-link"
            @click="child.action"
          >
            {{ child.title }}
          </span>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue'
import SvgSprite from '@/components/ui/SvgSprite'

defineProps({
  link: { type: Object, required: true },
  classList: { type: String, default: 'dropdown-menu__link' }
})

const show = ref(false)

// eslint-disable-next-line no-return-assign
const onClickOutside = () => show.value = false
</script>
<style lang="scss">
@import "src/assets/scss/variables";

.dropdown-wrapper {
  display: inline-block;
  position: relative;

  .dropdown__icon-wrapper {
    display: flex;
    align-items: center;
  }

  .dropdown-menu {
    background-color: $color-white;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 7.3rem;
    border-radius: .25rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    &:hover{
      background-color: darken($color-white, 15%);
    }

    ul {
      overflow: hidden;
      list-style-type: none;
      border-radius: .25rem;
      margin: 0 !important;

      & > li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;

        &:hover {
          background-color: #E8F0FC;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $color-border;
        }
      }
    }

    router-link {
      background-color: $color-white;
      display: flex;
      padding: 0.5rem 1rem;
      transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
      transition-duration: 300ms;
      &:hover{
        background-color: darken($color-white, 15%);
      }
    }

    &__icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
    }

    &__link {
      color: $color-text-darker;
      font-size: $default-font-size;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.2rem 1.6rem;
      cursor: pointer;
      transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
      transition-duration: 300ms;

      & > span {
        padding-left: 1rem;
      }

      &:hover {
        color: $color-white;
      }

      &.router-link-exact-active {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    &__sub-link {
      color: $color-text-dark;
      font-size: $default-font-size;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
      width: 100%;
      padding: .8rem 1.2rem;
      cursor: pointer;
      transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
      transition-duration: 300ms;

      & > span {
        padding: 0 1rem;
      }

      &.router-link-exact-active {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
}

/* Slide-in-up animation*/
.slide-in-up-enter-active, .slide-in-up-leave-active {
  transition: all .5s;
  transform: translateY(0);
}
.slide-in-up-enter-from, .slide-in-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
